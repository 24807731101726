import STORE_1 from "../assets/images/STORE_1.jpg";
import STORE_2 from "../assets/images/STORE_2.jpg";
import STORE_3 from "../assets/images/STORE_3.jpg";
import STORE_4 from "../assets/images/STORE_4.jpg";

export const team = [
  {
    title: "CEO",
    authorPhoto: {
      src: "",
      srcSet:
        "",
    },
    authorName: "M Rajesh Reddy",
  },
  {
    title: "HR",
    authorPhoto: {
      src: "",
      srcSet:
        "",
    },
    authorName: "P V Rao",
  },
  {
    title: "Administration",
    authorPhoto: {
      src: "",
      srcSet:
        "",
    },
    authorName: "N Ganesh",
  },
];

export const gallery = [
  {
    image: {
      src: STORE_1,
      srcSet: STORE_1,
    },
    title: "Gachibowli",
    cols: 1,
  },
  {
    image: {
      src: STORE_2,
      srcSet: STORE_2,
    },
    title: "Nacharam",
    cols: 3,
  },
  {
    image: {
      src: STORE_3,
      srcSet: STORE_3,
    },
    title: "Cherlapally",
    cols: 3,
  },
  {
    image: {
      src: STORE_4,
      srcSet: STORE_4,
    },
    title: "Gachibowli",
    cols: 3,
  },
];

export const adminInformation = {
  adminEmail: "contact_ho@taglinetraders.com",
  phoneNo: "8459258752",
  address:
    "PLOT NO-4 LAXMI APARTMENT VINAYAKA NAGAR UCO BANK STREET,GACHIBOWLI-BACK SIDE BAJAJ ELECTRONICS, HYDERABAD, TELANGANA",
  zipCode: "500032",
  lat_long: "17.432050,78.372660",
};

export const vishudha_image = {
  imgUrl: "../assets/images/logo.jpg",
};

export const order_type = ['Domestic'];

export const service_city = ['Hyderabad','Jadcherla','Vikarabad','Zaheerabad']

export const payment_mode_single_multiple = ['Single','Multiple']
export const payment_types = ['gpay','upi','cash','cheque','cod']
export const role_types = ['CEO_ROLE','ADMIN_ROLE','FINANCE_MANAGER_ROLE','WAREHOUSE_INCHARGE_ROLE','STORE_INCHARGE_ROLE',
'VEHICLE_INCHARGE_ROLE','DRIVER_ROLE']

export const about_this_product  = {
	"EO":[
	"Contains Healthy fats that control cholesterol and boosts heart health.",
	"Helps to fight bacteria in the mouth and is great for oral health.",
	"Increases fat burning and helps to reduce belly fat.",
	"Improves insulin sensitivity and helps to control blood sugar.",
	"Immense antioxidant, antimicrobial and antibacterial properties that protect our health.",
	],
	"DP":[
	"Pulses are a great source of vitamins,minerals and proteins.",
	"Pulses possess antioxidant effects and good for the heart.It reduce the risk of diabetes.",
	"Aids Weight Loss.Dall helps enhance the functioning of the cholecystokinin hormone.This yellow dal is rich in potassium and iron.",
	"Dall is a nutrient-rich food.Improves Digestive Health.Boosts Blood Circulation.",
	],
	"RC":[
	"Low in saturated fat,including omega-3 linolenic acid.",
	"An excellent source of carbohydrates.A significant source of protein.",
	"A good source of B group vitamins & Minerals,including folate, such as iron, magnesium, copper, phosphorus and zinc.",
	"A good source of antioxidants and phytochemicals that can help lower blood cholesterol levels.",
	],
	"FA":[
	"Flour is an excellent source of protein, vitamins, fibre and complex carbohydrates.",
	"It is also low in fat and cholesterol.",
	"Suji may be used to improve immunity, in case of diabetes, lower cholesterol, improve heart health.",
	"Reduce the risk of colon cancer, improve digestive function and as a prebiotic.",
	],
	"SM":[
	"Helps With Digestion.Provides Strong Antioxidants.Defends Against Diabetes.",
	"Regulates Blood Pressure & Improves Heart Health.Reduces Inflammation.",
	"Lowers Cancer Risk.Fights Oral Bacteria.Enhances Nutrient Absorption.",
	"Simply spicing up your meal can increase its overall nutritional value.",
	],
	"VSM":[
	"The Real Aroma of Quality.",
	"Manufactured With The Latest Equipement Totally Untouched By Hands.",
	"Masalas is rich in anti inflammatory and anti-oxidative properties.",
	"Each type of spice powder has a key ingredient that gives it a unique flavor to the dish in which it is used.",
	]
}

export const cancellation_policies = [
  "Applicable products are returnable within the applicable return window if you’ve received them in a condition that is physically damaged, has missing parts or accessories, defective or different from their description on the product detail page on Amazon.in.",
"If you report an issue with your Smartphone, Tablet, Laptop, Television, Air Conditioner, Refrigerator, Washing Machine, Microwave, we may facilitate scheduling a technician visit to your location. This visit is scheduled by placing an order with Amazon through Amazon.in Home Services. Subject to Amazon.in Home Services Terms and Conditions, a resolution will be provided based on the technician's evaluation report.",
"Return will be processed only if: it is determined that the product was not damaged while in your possession the product is not different from what was shipped to you",
"the product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and all the accessories therein).",
"If you wish to return an electronic device that stores any personal information, please ensure that you have removed all such personal information from the device prior to returning. Amazon shall not be liable in any manner for any misuse or usage of such information.",
"Products may not be eligible for return in some cases, including cases of buyer's remorse such as incorrect model or color of product ordered or incorrect product ordered",
"Products marked as non-returnable on the product detail page cannot be returned. However, in an unlikely event of damaged, defective or wrong item delivered to you, we will provide a full refund or replacement, as applicable. We may contact you to ascertain the damage or defect in the product prior to issuing refund/replacement. We reserve the right to pick up the product to ascertain the damage or defect in the product prior to issuing refund/replacement.",
"No additional information is required to return an eligible order unless otherwise noted in the category specific policy. ",
"Products may be eligible for replacement only if the same seller has the exact same item in stock. ",
"If the replacement request is placed and the seller does not have the exact same product in stock, a refund would be issued to you. ",
"Products purchased by international customers are not eligible for returns. However, orders made by international customers are eligible for refunds and customers will have to contact customer service within 5 business days from delivery date or estimated delivery date to claim refunds.",
"To know about the Return window for Amazon Business orders, please visit here. ",
" In the event customers are found to misuse the return policy by excessively returning, or cancelling or not accepting the orders placed, Amazon reserves the right to warn and/or suspend and/or block and/or terminate such customer accounts, as necessary"
]

export const best_sellers_sequence = 
[
  "RUCHI GOLD VALUE PACK PALM OIL 850 GM POUCH",
  "VISHUDHA RED CHILLI POWDER 500GM PIECE",
  "VISHUDHA TURMERIC POWDER 500 GM PIECE",
  "VISHUDHA CORIANDER POWDER 500 GM PIECE",
  "VISHUDHA SALT 1 KG POUCH", 
  "GOLD DROP SF OIL 1LTR POUCH"
  // "RUCHI GOLD PALM OIL 1 LTR POUCH"
];
export const about_the_product = ["Highly Authentic: Enjoy the rich taste of this in all your favourite dishes",
"Quality Guaranteed: Contains no added preservatives","No Colors added","Hand picked and hiegenic"]

export const brand_details = [
  {name:"brand",value:"Vishudha"},
  {name:"variety",value:"Turmeric"},
  {name:"itemFrom",value:"Powder"},
  {name:"netQuantity",value:"200gm"},
  {name:"dietType",value:"Vegetarian"},
  ]

export const vishudha_policies = [
  {name:"Free Delivery",imgIcon:"icon-delivery"},
  {name:"Pay on Delivery",imgIcon:"icon-cod"},
  {name:"Non-Returnable",imgIcon:"icon-non-returns"},
  {name:"Top Brand",imgIcon:"icon-top-brand"},
  {name:"Our Delivery",imgIcon:"icon-vishudha-delivered"},
  {name:"Secure transaction",imgIcon:"icon-secure-pay"}]
  
  export const main_categories_sequence = 
  [
    "Rice & Cereal",
    "Edible Oils",
    "Pulses & Dals",
    "Atta",
    "Spices & Masalas",
    "Tea Sugar/Salt",
    // LSK : COMMENTING FOR PHONEPE KYC TO BE DONE
    // "Vegetables",
    // "Eggs",
    // "FMCG"
  ];