import React from "react";
import { makeStyles } from "@mui/styles";
import { Section } from "./organisms";
import { team, gallery } from "../data";
import { Divider, Grid, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import { terms } from "../data/termsConditions.js";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: "0 5px 20px 0 rgba(90, 202, 157, 0.05)",
    "& .section-alternate__content": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const TermsConditions = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div style={{marginTop : "1rem"}}>
      <Grid item xs={12} sm={12} md={12} style={{marginTop : "0.1rem"}}>
      <Section style={{marginTop : "0.1rem",padding:"1rem"}}>
        <div>
          <h3 style={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize:"1.5rem",
            fontWeight : "600",
            color: '#000',
            textAlign: "left",
            margin: '1em',
            fontSize : "1.5rem"
            }}>
            Terms Conditions 
          </h3>
          <Divider style={{ margin: "0.5rem" }} />
        {terms.map((t,index) =>(
        <ul key={index}>
          <h3>{t.title}</h3>
          <div style={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize:"0.85rem",
            fontWeight : "300",
            color: 'gray',
            textAlign: "left",
            textTransform: "none",
            margin: '1em'
            }}>{t.content?t.content : t.actionPoints?t.actionPoints.map(pt=> (<ul><li>{pt}</li></ul>)):null}
            </div>
        </ul>
        )
        )}  
        </div>
      </Section>
      </Grid>
    </div>
  );
};

export default TermsConditions;
